import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Container } from 'reactstrap'
import Section from '../components/common/Section'
import Layout from '../components/common/Layout'

const StaticPage = ({ data }) => (
  <Layout>
    <Helmet title={data.markdownRemark.frontmatter.title}>
      <body className="has-navbar-fixed-top site-2020" />
    </Helmet>
    <Section section="section--fit">
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Section>
  </Layout>
)

export default StaticPage

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
